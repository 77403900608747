import { Typography } from "@mui/material";

type Props = {
  correct: number;
  total: number;
};

const getFinishText = (correct: number, total: number) => {
  const pct = correct / total;

  if (pct === 1) {
    return <Typography variant="h2">🥵💦</Typography>;
  }

  if (pct > 0.75) {
    return <Typography variant="h6">Want to go again? 😳</Typography>;
  }

  if (pct > 0.5) {
    return <Typography variant="h6">Almost ... maybe next time</Typography>;
  }

  return <Typography variant="h6">It's ok. It happens to everyone</Typography>;
};

export const Score = ({ correct, total }: Props) => {
  return (
    <>
      <Typography variant="h3">
        {correct}/{total}
      </Typography>
      {getFinishText(correct, total)}
    </>
  );
};
