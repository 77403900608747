import { useState } from "react";
import { answerQuestion, createGame } from "../services/games";
import { Layout } from "../components/Layout";
import { Score } from "../components/Score";
import { Question } from "../components/Question";
import { ProgressBar } from "../components/ProgressBar";

/**
 * TODO:
 * - Progress bar
 * - Ping sounds
 */
export const Game = () => {
  const [game, setGame] = useState(createGame());

  const { questions, currentQuestionIndex, score } = game;
  const currentQuestion = questions[currentQuestionIndex];

  const registerAnswer = (selectedAnswer: number) => {
    const updatedGame = answerQuestion(game, selectedAnswer);

    setGame(updatedGame);
  };

  if (game.isDone) {
    return (
      <Layout>
        <Score correct={score} total={questions.length}></Score>
      </Layout>
    );
  }

  return (
    <Layout>
      <ProgressBar current={currentQuestionIndex} total={questions.length} />
      <Question question={currentQuestion} registerAnswer={registerAnswer} />
    </Layout>
  );
};
