import "./App.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { Game } from "./pages/Game";
import { useState } from "react";
import { Intro } from "./pages/Intro";

function App() {
  const [haveEntered, setHaveEntered] = useState(false);

  if (haveEntered) {
    return <Game />;
  }

  return <Intro onFinish={() => setHaveEntered(true)} />;
}

export default App;
