import { emojisplosion } from "emojisplosion";
import {  useEffect, useState } from "react";
import { Coordinates } from "../components/Coordinates";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import Stack from "@mui/material/Stack";

type EscalationConfig = { emojis: string[]; message: string; qty: number }[];

const escalationConfig: EscalationConfig = [
  {
    message: "Touch here",
    emojis: ["😳"],
    qty: 3,
  },
  {
    message: "Do it again",
    emojis: ["🤤", "🔥"],
    qty: 6,
  },
  {
    message: "Don't stop",
    emojis: ["🥵", "🔥", "🔥"],
    qty: 9,
  },
];

// const climaxEmoji = "💦";
const climaxText = "🫠🫠🫠";

type Props = { 
  onFinish: () => void
}

export const Intro = ({ onFinish }: Props) => {
  const [arousalLevel, setArousalLevel] = useState(0);
  const [hasClimaxed, setHasClimaxed] = useState(false);
  const [coords, setCoords] = useState({ x: 0, y: 0 });

  const escalate = () => {
    emojisplosion({
      emojiCount: escalationConfig[arousalLevel].qty,
      emojis: escalationConfig[arousalLevel].emojis,
      position: coords,
    });

    setArousalLevel(arousalLevel + 1);
  };

  useEffect(() => {
    if (arousalLevel >= escalationConfig.length) {
      setHasClimaxed(true);

      onFinish()
      // TODO: Keep emoji explosion and show loader for game

      // setTimeout(() => {
      //   emojisplosions({
      //     emojiCount: 3,
      //     emojis: [climaxEmoji],
      //     interval: 1_000,
      //   });
      // }, 1_000);


    }
  }, [arousalLevel, onFinish]);

  return (
    <Box>
      <h1
        style={{
          position: "absolute",
          background: "#0b6bcb",
          color: "white",
          padding: "8px 32px 8px 16px",
          width: "auto",
          display: "inline",
          margin: "10px",
        }}
      >
        Dadolingo
      </h1>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ width: 1, height: "100vh" }}
      >
        <Box>
          <h1>Cum Inside</h1>
          <Coordinates onChange={setCoords}>
            <Button
              variant="contained"
              fullWidth={true}
              onClick={escalate}
              disabled={hasClimaxed}
            >
              {hasClimaxed
                ? climaxText
                : escalationConfig[arousalLevel]?.message}
            </Button>
          </Coordinates>
        </Box>
      </Stack>
    </Box>
  );
};
