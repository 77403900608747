import { FC, useEffect, useRef, useState } from "react";

export type Coords = { x: number; y: number };

type CoordinatesProps = {
  children: React.ReactNode;
  onChange: (coords: Coords) => void;
};

export const Coordinates: FC<CoordinatesProps> = ({ children, onChange }) => {
  const boxRef = useRef<HTMLDivElement>(null);
  const [coords, setCoords] = useState<Coords>({ x: 0, y: 0 });

  const getPosition = () => {
    const x = boxRef.current?.offsetLeft ?? 0;
    const y = boxRef.current?.offsetTop ?? 0;

    setCoords({ x, y });
  };

  useEffect(() => {
    getPosition();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", getPosition);
  }, []);

  useEffect(() => {
    onChange(coords);
  }, [coords, onChange]);

  return <div ref={boxRef}>{children}</div>;
};
