import { Dictionary } from "./types";

// TODO: Add an exclusions category for where words mean the same thing
export const dictionary: Dictionary = [
  {
    es: { value: "ponerse cachondo/a" },
    en: { value: "to make oneself horny" },
  },
  {
    es: { value: "dar un beso negro" },
    en: { value: "give a rimjob" },
  },
  {
    es: { value: "sexo anal" },
    en: { value: "anal sex" },
  },
  {
    es: { value: "correrse dentro" },
    en: { value: "to come inside" },
  },
  {
    en: { value: "lesbian" },
    es: { value: "lesbiana" },
  },
  {
    en: { value: "lube" },
    es: { value: "el lubricante" },
  },
  {
    en: { value: "cowgirl" },
    es: { value: "posición de vaquera" },
  },
  {
    en: { value: "water sports" },
    es: { value: "orinadas" },
  },
  {
    en: { value: "deepthroat" },
    es: { value: "garganta profunda" },
  },
  {
    en: { value: "fetish" },
    es: { value: "fetiches" },
  },
  {
    en: { value: "to cuckold" },
    es: { value: "poner los cuernos" },
  },
  {
    en: { value: "fingering" },
    es: { value: "al dedo" },
  },
  {
    en: { value: "fisting" },
    es: { value: "joder con el puño" },
  },
  {
    en: { value: "hardcore" },
    es: { value: "sexo duro" },
  },
  {
    en: { value: "toys" },
    es: { value: "juguetes" },
  },
  {
    en: { value: "threesome" },
    es: { value: "trío" },
  },
  {
    en: { value: "strip tease" },
    es: { value: "estriptís" },
  },
  {
    en: { value: "roleplay" },
    es: { value: "juego de rol" },
  },
  {
    en: { value: "pussy licking" },
    es: { value: "lamidas de coño" },
  },
  {
    en: { value: "blowjob" },
    es: { value: "mamada" },
  },
  {
    en: { value: "masturbation" },
    es: { value: "masturbación" },
  },
  {
    en: { value: "handjob" },
    es: { value: "paja" },
  },
];
