import { dictionary } from "./data";
import { random } from "lodash";

export type Question = {
  prompt: string;
  answers: string[];
  correctAnswerIndex: number;
};
export type Game = {
  questions: Question[];
  currentQuestionIndex: number;
  score: number;
  isDone: boolean
};

const getUniqueIndexes = (
  max: number,
  count: number,
  exclude: number[] = []
): number[] => {
  const indexes: number[] = [];
  let i = 0;
  while (indexes.length < count) {
    i++;

    if (i > 100) {
      break;
    }

    const next = random(0, max);

    if (indexes.includes(next)) {
      continue;
    }

    if (exclude.includes(next)) {
      continue;
    }

    indexes.push(next);
    console.log('indexes', indexes, count)

  }

  return indexes;
};

/**
 * TODO:
 * - Allow reverse translations
 */
export const createGame = (): Game => {
  const questions: Question[] = [];
  const questionCount = 10;

  if (questionCount > dictionary.length) {
    throw new Error(
      "Cannot generate a game with more questions than dictionary entries"
    );
  }

  const indexes: number[] = getUniqueIndexes(
    dictionary.length - 1,
    questionCount
  );

  for (const index of indexes) {
    const correctAnswerIndex = random(0, 2);

    const badAnswers = getUniqueIndexes( dictionary.length - 1,2, [index]).map(
      (i) => dictionary[i].es.value
    );
    const goodAnswer = dictionary[index].es.value;

    const answers = [
      ...badAnswers.slice(0, correctAnswerIndex),
      goodAnswer,
      ...badAnswers.slice(correctAnswerIndex),
    ];

    questions.push({
      correctAnswerIndex,
      answers,
      prompt: dictionary[index].en.value,
    });
  }

  console.log(questions,  questions )

  return {
    questions,
    score: 0,
    currentQuestionIndex: 0,
    isDone: false,
  };
};

export const answerQuestion = (game: Game, answerIndex: number): Game => {
  const question = game.questions[game.currentQuestionIndex];
  const isCorrect = question.correctAnswerIndex === answerIndex;


  return {
    ...game,
    currentQuestionIndex: game.currentQuestionIndex + 1,
    score: game.score + (isCorrect ? 1 : 0),
    isDone: game.currentQuestionIndex === game.questions.length - 1
  };
};
