import { Typography } from "@mui/material";

type Props = {
  current: number;
  total: number;
};

export const ProgressBar = ({ current, total }: Props) => {
  return (
    <Typography variant="body1" align="right" color="text.secondary">
      {current}/{total}
    </Typography>
  );
};
