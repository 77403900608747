import { Box, Container, Stack } from "@mui/material";
import { PropsWithChildren } from "react";
import { Logo } from "./Logo";

export const Layout = ({ children }: PropsWithChildren) => {
  return (
    <>
      <Logo />
      <Container
        sx={{
          width: {
            xs: "100%",
            sm: "75%",
            md: "60%",
            lg: "50%",
            xl: "33%",
          },
        }}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ width: 1, height: "100vh" }}
        >
          <Box sx={{ margin: "12px", padding: "12px" }}>{children}</Box>
        </Stack>
      </Container>
    </>
  );
};
