import { Game } from "../services/games";
import { useEffect, useState } from "react";
import { Alert, Button, CardActionArea, Grid, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CheckIcon from "@mui/icons-material/Check";

type Props = {
  question: Game["questions"][number];
  registerAnswer: (answerIndex: number) => void;
};

export const Question = ({ question, registerAnswer }: Props) => {
  const [selectedAnswer, setSelectedAnswer] = useState<number>();
  const [hasAnswered, setHasAnswered] = useState(false);

  useEffect(() => {
    setSelectedAnswer(undefined);
    setHasAnswered(false);
  }, [question]);

  const onClickSubmit = () => {
    setHasAnswered(true);
  };

  const onClickNext = () => {
    registerAnswer(selectedAnswer!);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" color="text.secondary">
          Which of these is "{question.prompt}"?
        </Typography>
      </Grid>
      {question.answers.map((answer, i) => (
        <Grid
          item
          xs={12}
          sm={4}
          display={"flex"}
          sx={{
            pointerEvents: hasAnswered ? "none" : "inherit",
          }}
        >
          <CardActionArea onClick={() => setSelectedAnswer(i)}>
            <Card
              key={i}
              style={{
                background: selectedAnswer === i ? "#249af854" : "inherit",
                width: "100%",
                height: "100%",
              }}
            >
              <CardContent style={{ height: "100%" }}>
                <Typography color="text.secondary" textAlign="center">
                  {answer}
                </Typography>
              </CardContent>
            </Card>
          </CardActionArea>
        </Grid>
      ))}
      <Grid item xs={12}>
        {hasAnswered ? (
          <>
            {selectedAnswer === question.correctAnswerIndex ? (
              <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                Correct!
              </Alert>
            ) : (
              <Alert severity="error">
                Incorrect! The correct answer is "
                {question.answers[question.correctAnswerIndex]}"
              </Alert>
            )}
            <Button
              variant="contained"
              style={{ marginTop: "24px" }}
              fullWidth={true}
              onClick={() => onClickNext()}
            >
              Continue
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            style={{ marginTop: "24px" }}
            fullWidth={true}
            disabled={selectedAnswer === undefined}
            onClick={() => onClickSubmit()}
          >
            Submit
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
