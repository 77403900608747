export const Logo = () => {
  return (
    <h1
      style={{
        position: "absolute",
        background: "#0b6bcb",
        color: "white",
        padding: "8px 32px 8px 16px",
        width: "auto",
        display: "inline",
        margin: "10px",
      }}
    >
      Dadolingo
    </h1>
  );
};
